export default {
  colors: {
    text: '#666666',
    background: '#fff',
    pastel: '#FEFDF8',
    beige: '#f1eddc',
    red: '#F05826'
  },
  fonts: {
    default: '"Mabry Pro", Courier, Monaco',
    heading: '"Pondre", Courier, Monaco'
  },
  fontWeights: {
    body: 400,
    heading: 700
  },
  fontSizes: {
    default: 12
  },
  components: {
    link: {
      textDecoration: 'none',
      transition: 'all 100ms ease-in-out',
      color: 'black',
      '&:hover, &:active': {
        color: 'red'
      }
    },
    reset: {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'black',
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'default',
      '&:focus': {
        outline: 'none',
        background: 'none'
      }
    }
  },
  breakpoints: ['40em', '56em', '64em'],
  styles: {
    root: {
      fontFamily: 'default',
      fontWeight: 300,
      fontSize: 'default',
      opacity: 1,
      transition: 'opacity 500ms linear',
      color: 'black',
      fontSize: 16,
      backgroundColor: 'beige'
    }
  }
}

import * as React from 'react'
import ContextProvider from '~/shopify/ContextProvider'
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
console.groupCollapsed('\n🍷PLANQUE 🍇\n\n')
console.log('🍷https://tastefullymade.com/🍇')
console.groupEnd()

const vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

export const wrapRootElement = ({element}) => (
  <ContextProvider>{element}</ContextProvider>
)
